
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "view-users-modal",
  components: {},
  data(){
    return {
      users: [],
      filtered: [],
      ticket_input: {},
      loading: false
    }
  },
  methods: {
    getusers(){
      this.axios.get('/users').then(res=>{
        this.filtered = res.data.users
        this.users = res.data.users
      })
    },
    search(e){
      let query = e.target.value.toLowerCase()
      this.filtered = this.users.filter(user=>user.email.toLowerCase().includes(query) || user.name.toLowerCase().includes(query))
    },
    async startticket(user){
      
      try{
        this.loading = true
        let res = await this.axios.post('/ticket', {
          message: this.ticket_input[user.uid],
          user: user.uid,
          name: user.name
        })
        
        this.loading = false

        if(res.data.success){
          Swal.fire({
              text: res.data.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Okay",
              customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
              },
          })
          setTimeout(()=>this.$router.go(0), 2000)
        }else{
          Swal.fire({
              text: res.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
              },
          })
        }
      }catch(e){
        this.loading = false
        Swal.fire({
            text: e.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
            },
        })
      }
    }
  },
  mounted(){
    this.getusers()
  }
});
